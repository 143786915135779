import React from "react";

const Navigation = () => {
  return (
    <div className="navigation">
      <div className="container">
        <div className="site-title">
          <span className="small">digital art by</span>
          <span className="name">amy</span>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
