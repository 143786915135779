export const ENVIRONMENT = "PROD";

export const GANACHE_CONTRACT = "";
export const PROD_CONTRACT = "0x7C7D1A075fDcF634FFbD1fD1de161948906C1dcb";
export const MUMBAI_CONTRACT = "0xe01ff244adcd13d2cd7aa126ceb5a9969acdb4c4";

export const PROD_PRICE = 88;
export const MUMBAI_PRICE = 0.044;

export const PROD_NETWORK_ID = 137;
export const MUMBAI_NETWORK_ID = 80001;

export const getPrice = (environment) => {
  return environment === "PROD" ? PROD_PRICE : MUMBAI_PRICE;
};

export const getContractAddress = (environment) => {
  return environment === "PROD" ? PROD_CONTRACT : MUMBAI_CONTRACT;
};

export const getNetworkID = (environment) => {
  return environment === "PROD" ? PROD_NETWORK_ID : MUMBAI_NETWORK_ID;
};
