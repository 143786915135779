import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import * as config from "./config";

export const getPromoImageNum = () => {
  const minImage = 1;
  const maxImage = 14;
  return Math.floor(Math.random() * (maxImage - minImage + 1)) + minImage;
};

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);

  const TOTAL_SUPPLY = 444;
  const PRICE = config.getPrice(config.ENVIRONMENT);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Firing up the engine...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        to: config.getContractAddress(config.ENVIRONMENT),
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(
          (PRICE * _amount).toString(),
          "ether"
        ),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong. Please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          <span>
            Success! View on{" "}
            <a target="_blank" href="https://opensea.io/account">
              OpenSea
            </a>{" "}
            <br />. (May take a few minutes to appear!)
          </span>
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const promoImageNum = getPromoImageNum();

  return (
    <div className="app">
      <Navigation />
      <div className="container">
        <div className="content-wrapper">
          <div className="header">
            <h1>GENDOTS</h1>

            <p>Order, disorder and lots of dots.</p>

            <p>
              This is GENDOTS, the first generative NFT collection created by
              Amy Peniston.
            </p>

            <p>
              View on{" "}
              <a target="_blank" href="https://opensea.io/collection/gendots">
                OpenSea
              </a>
            </p>
          </div>

          <img src={`/images/gendots/${promoImageNum}.jpg`} className="promo" />

          <h2 className="supply">
            {data.totalSupply === 0 ? "?" : data.totalSupply}/{TOTAL_SUPPLY}
          </h2>

          {Number(data.totalSupply) == TOTAL_SUPPLY ? (
            <div className="content">
              <p>The sale has ended.</p>
              <p>
                You can still find GENDOTS on{" "}
                <a target="_blank" href="https://opensea.io/collection/gendots">
                  OpenSea
                </a>
              </p>
            </div>
          ) : (
            <div className="content">
              <h3>Each NFT costs {PRICE} MATIC</h3>

              <p className="subtext">Excluding gas fee</p>

              <p>{feedback}</p>

              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <div>
                  <div>
                    <p className="message">
                      To mint, connect to the Polygon network
                    </p>
                  </div>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <p>{blockchain.errorMsg}</p>
                    </>
                  ) : null}
                  <div
                    className="button"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT
                  </div>
                </div>
              ) : (
                <div>
                  {claimingNft ? (
                    <div className="button">MINTING...</div>
                  ) : (
                    <div
                      className="button"
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      MINT 1
                    </div>
                  )}
                </div>
              )}

              <p className="disclaimer">
                Please make sure you are connected to Polygon Mainnet using your
                desired account. Once you confirm a purchase, there is no undo.
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
