import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";

const SocialIcon = ({ icon, link }) => (
  <a href={link} target="_blank">
    <span className="social-icon">{icon}</span>
  </a>
);

const Footer = () => {
  return (
    <div className="footer">
      <div className="social-icons">
        <SocialIcon
          icon={<TwitterIcon />}
          link="https://twitter.com/amypeniston"
        />
      </div>
    </div>
  );
};

export default Footer;
